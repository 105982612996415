import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./application/store";
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { ChakraProvider } from '@chakra-ui/react';
import theme from './theme/theme';
import { ThemeEditorProvider } from '@hypertheme-editor/chakra-ui';
import i18n from './localization/i18n';
import App from './app';
import 'assets/css/App.css';
import 'react-toastify/dist/ReactToastify.css';

let persistor = persistStore(store);

ReactDOM.render(
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<BrowserRouter>
				<ChakraProvider theme={theme}>
					{/* <React.StrictMode> */}
						{/* <ThemeEditorProvider> */}
							<App />
						{/* </ThemeEditorProvider> */}
					{/* </React.StrictMode> */}
				</ChakraProvider>
			</BrowserRouter>
		</PersistGate>
	</Provider>,
	document.getElementById('root')
);
